import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AppState } from 'src/app/store/app.state';
import { selectNotification } from 'src/app/store/notification/notification.selectors';
import { Notification } from 'src/app/store/notification/notification.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  notification$: Observable<any> = this.store.select(selectNotification);

  constructor(
    private store: Store<AppState>,
    private notificationService: NotificationService) {
    this.notification$.subscribe(async (notification: Notification) => {
      this.notificationService.show(notification);
    });
  }
}
