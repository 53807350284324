import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CsrfService {
  constructor(private http: HttpClient) {}

   getCsrfToken() {
     return this.http.get<string>('/csrf-token', { responseType: 'text' as 'json'});
   }
}
