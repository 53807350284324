import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { User } from 'src/app/store/user/user.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit  {
  href: string;
  isAuthenticated$: Observable<boolean> = this.userService.isAuthenticated();
  currentUser: User;
  isAdmin: boolean;
  isSupervisor: boolean;
  isManager: boolean;
  version: string;
  @ViewChild('DeconnectionButton') deconnectionButton: ElementRef;
  
  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private header: ElementRef,
    private httpClient: HttpClient,
    private storageService: StorageService) { }


  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.isAdmin = this.currentUser && this.currentUser.isAdmin;
    this.isSupervisor = this.currentUser && this.currentUser.isSupervisor;
    this.isManager = this.currentUser && this.currentUser.isManager;

    if (!this.version) {
      this.httpClient
        .get('assets/version.txt', { responseType: 'text' })
        .subscribe((version: string) => {
        this.version = version;
      })
    }
  }

  logout() {
    this.userService.logout();
  }

  onMenu() {
    const buttons = this.header.nativeElement.querySelectorAll('.deconnection-button');
    if (buttons && buttons.length > 1) {
      this.renderer.listen(buttons[1], 'click', () => {
        this.logout();
      });
    }
  }
}
