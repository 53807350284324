import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { AppState } from 'src/app/store/app.state';
import { Network } from 'src/app/store/network/network.model';
import { User } from 'src/app/store/user/user.model';
import { NetworkService } from 'src/app/core/services/network.service';
import { selectNetworks } from 'src/app/store/network/network.selectors';
import { createUser } from 'src/app/store/user/user.actions';

@Component({
  selector: 'app-create-modal',
  templateUrl: './create-modal.component.html',
})
export class CreateModalComponent implements OnInit {

  createForm: FormGroup;
  isSubmitted = false;
  currentUser: User;
  isAdmin: boolean;
  isSupervisor: boolean;
  isManager: boolean;
  networkInput$ = new Subject<string>();
  networkLoading = false;
  networks$: Observable<Network[]> = this.store.select(selectNetworks);
  roles: any[];
  selectedRole: string;

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    private storageService: StorageService,
    private networkService: NetworkService,
    private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.isAdmin = this.currentUser && this.currentUser.isAdmin;
    this.isSupervisor = this.currentUser && this.currentUser.isSupervisor;
    this.isManager = this.currentUser && this.currentUser.isManager;
    this.networks$ = this.networkService.getAll();
    this.roles = [
      {
        name: "Agent ONDT",
        active: this.isAdmin,
        selected: this.isAdmin
      },
      {
        name: "Responsable",
        active: this.isSupervisor || this.isManager,
        selected: this.isSupervisor
      },
      {
        name: "Agent",
        active: this.isManager,
        selected: this.isManager
      }
    ]
    this.selectedRole = this.roles.filter(_role => _role.selected)[0].name;
    
    this.createForm = this.formBuilder.group({
      firstname: ['', [
        Validators.required,
        Validators.minLength(2)
      ]],
      lastname: ['', [
        Validators.required,
        Validators.minLength(2)
      ]],
      role: [this.selectedRole, [
        Validators.required
      ]],
      email: ['', [
        Validators.required,
        Validators.email,
      ]],
      networkIds: new FormControl([], Validators.required)
    });

    if (this.isAdmin) {
      this.createForm.get('networkIds').clearValidators();
    }
  }

  onChange(value) {
    this.createForm.patchValue({
      role: value
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.createForm.valid) {
      return false;
    }

    const user = new User({
      firstname: this.createForm.value.firstname,
      lastname: this.createForm.value.lastname,
      email: this.createForm.value.email,
      role: this.createForm.value.role,
      networkIds: this.createForm.value.networkIds ?? [],
    })

    this.store.dispatch(createUser(user));
    this.modalController.dismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }

}
