import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { AppState } from 'src/app/store/app.state';
import { Network } from 'src/app/store/network/network.model';
import { User } from 'src/app/store/user/user.model';
import { NetworkService } from 'src/app/core/services/network.service';
import { selectNetworks } from 'src/app/store/network/network.selectors';
import { formatDate } from '@angular/common';
import { updateUser } from 'src/app/store/user/user.actions';

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
})
export class UpdateModalComponent implements OnInit {

  @Input() user: User;
  updateForm: FormGroup;
  isSubmitted = false;
  currentUser: User;
  isAdmin: boolean;
  isSupervisor: boolean;
  isManager: boolean;
  networkInput$ = new Subject<string>();
  networkLoading = false;
  networks$: Observable<Network[]> = this.store.select(selectNetworks);
  roles: any[];
  selectedRole: string;

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    private storageService: StorageService,
    private networkService: NetworkService,
    private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.isAdmin = this.currentUser && this.currentUser.isAdmin;
    this.isSupervisor = this.currentUser && this.currentUser.isSupervisor;
    this.isManager = this.currentUser && this.currentUser.isManager;
    this.networks$ = this.networkService.getAll();
    this.roles = [
      {
        name: "Agent ONDT",
        active: this.isAdmin,
        selected: this.isAdmin
      },
      {
        name: "Responsable",
        active: this.isSupervisor || this.isManager,
        selected: this.isSupervisor
      },
      {
        name: "Agent",
        active: this.isManager,
        selected: this.isManager
      }
    ]
    this.selectedRole = this.roles.filter(_role => _role.selected)[0].name;

    this.updateForm = this.formBuilder.group({
      firstname: [this.user.firstname, [
        Validators.required,
        Validators.minLength(2)
      ]],
      lastname: [this.user.lastname, [
        Validators.required,
        Validators.minLength(2)
      ]],
      role: [this.user.role, [
        Validators.required
      ]],
      networkIds: new FormControl(this.user.networks.map(_network => _network.id), Validators.required)
    });

    if (this.isAdmin) {
      this.updateForm.get('networkIds').clearValidators();
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.updateForm.valid) {
      return false;
    }

    this.user = {
      ...this.user,
      firstname: this.updateForm.value.firstname,
      lastname: this.updateForm.value.lastname,
      networkIds: this.updateForm.value.networkIds ?? [],
      isAdmin: this.user.isAdmin,
      isSupervisor: this.user.isSupervisor,
      isManager: this.user.isManager,
      isAgent: this.user.isAgent,
    }

    this.store.dispatch(updateUser(this.user));
    this.modalController.dismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }

}
