import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/core/services/storage.service';
import { User } from 'src/app/store/user/user.model';
import { FactPerson } from 'src/app/store/fact/fact-person.model';

@Component({
  selector: 'app-modify-protagonist-modal',
  templateUrl: './modify-protagonist-modal.component.html',
})
export class ModifyProtagonistModalComponent implements OnInit {

  @Input() currentProtagonist: FactPerson;
  createForm: FormGroup;
  isSubmitted = false;
  currentUser: User;
  isOther = false;

  constructor(
    private modalController: ModalController,
    private storageService: StorageService,
    private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.isOther = this.currentProtagonist.quality === 'Autre';
    this.createForm = this.formBuilder.group({
      quality: [this.currentProtagonist.quality, [
        Validators.required,
      ]],
      function: [this.currentProtagonist.function, [
        Validators.required,
      ]],
      gender: [this.currentProtagonist.gender, [
        Validators.required,
      ]],
      count: [this.currentProtagonist.count, [
        Validators.required,
        Validators.min(0),
      ]],
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.createForm.valid) {
      return false;
    }

    const person = new FactPerson({
      ...this.currentProtagonist,
      quality: this.createForm.controls.quality.value,
      function: this.createForm.controls.function.value,
      gender: this.createForm.controls.gender.value,
      count: this.createForm.controls.count.value,
    })

    this.modalController.dismiss(person);
  }

  onChangeQuality(quality) {
    this.isOther = quality === 'Autre';
  }

  onDismiss() {
    this.modalController.dismiss();
  }

}
