import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from './../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './storage.service';
import { map } from 'rxjs/operators';
import { User } from 'src/app/store/user/user.model';
import { UserQuery } from '../models/user-query.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private jwtHelper: JwtHelperService;
  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
  ) {
    this.jwtHelper = new JwtHelperService();
   }

  isAuthenticated(): Observable<boolean> {
    return this.httpClient.get(`${env.apiUrl}/users/current`).pipe(
      map((user) => {
        const loggedUser = new User(user);
        if (user && !loggedUser.isAgent) {
          this.storageService.set('User', loggedUser);
          return true;
        } else {
          this.storageService.clear();
          return false;
        }
      })
    );

  }

  login() {
    window.location.replace(`${env.apiUrl}/users/login`);
  }

  logout() {
    window.location.replace(`${env.apiUrl}/users/logout`);
  }

  relogin(username: string, refreshToken: string): Observable<any> {
    return this.httpClient.post(`${env.apiUrl}/users/relogin`, { username, refreshToken });
  }

  get(query: UserQuery): Observable<any> {
    let params = new HttpParams();
    if (query.page) {
      params = params.append('page', query.page);
    }
    if (query.limit) {
      params = params.append('limit', query.limit);
    }
    if (query.name) {
      params = params.append('name', query.name);
    }

    return this.httpClient.get(`${env.apiUrl}/users`, { params });
  }

  create(user: User): Observable<any> {
    return this.httpClient.post(`${env.apiUrl}/users`, {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      role: user.role,
      networkIds: user.networkIds,
    });
  }

  update(user: User): Observable<any> {
    return this.httpClient.put(`${env.apiUrl}/users/${user.uuid}`, {
      firstname: user.firstname,
      lastname: user.lastname,
      role: user.role,
      networkIds: user.networkIds,
    });
  }

  delete(user: User): Observable<any> {
    return this.httpClient.delete(`${env.apiUrl}/users/${user.uuid}`);
  }
}
