import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { NgxPaginationModule } from 'ngx-pagination';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user/user.effects';
import { StoreModule } from '@ngrx/store';
import * as fromUser from './store/user/user.reducers';
import * as fromFact from './store/fact/fact.reducers';
import * as fromNetwork from './store/network/network.reducers';
import * as fromNotification from './store/notification/notification.reducers';
import { LoginPageModule } from './pages/login/login.module';
import { SharedModule } from './modules/shared/shared.module';
import { NetworkEffects } from './store/network/network.effects';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HttpRequestInterceptor } from './core/interceptors/http-request-interceptor';
import { FactEffects } from './store/fact/fact.effects';
import { LegalPageModule } from './pages/legal/legal.module';
import { AccessibilityPageModule } from './pages/accessibility/accessibility.module';
import { CsrfInterceptor } from './core/interceptors/csrf.interceptor';

registerLocaleData(localeFr, 'fr');

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [BrowserModule,
        IonicModule.forRoot(),
        NgxPaginationModule,
        AppRoutingModule,
        LoginPageModule,
        SharedModule,
        LegalPageModule,
        AccessibilityPageModule,
        StoreModule.forRoot({}),
        StoreModule.forFeature(fromNotification.featureKey, fromNotification.reducer),
        StoreModule.forFeature(fromNetwork.featureKey, fromNetwork.reducer),
        StoreModule.forFeature(fromUser.featureKey, fromUser.reducer),
        StoreModule.forFeature(fromFact.featureKey, fromFact.reducer),
        IonicStorageModule.forRoot({
            name: '_appisisdb',
            driverOrder: [Drivers.LocalStorage]
        }),
        EffectsModule.forRoot([UserEffects, NetworkEffects, FactEffects])],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
          provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
