import { Component, OnInit } from '@angular/core';
import { CsrfService } from './core/services/csrf.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    constructor(private csrfService: CsrfService) {}

    ngOnInit() {
      this.csrfService.getCsrfToken();
    }
}
